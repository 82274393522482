/* VARS - WEBSITE =====================================
======================================================= */
$white: #FFFFFF;
$black: #000000;

// General

// FONTS ============================================== */
$baseFont: 'Barlow', sans-serif;
$copyFont: 'League Gothic', sans-serif;

/* BREAKPOINTS ======================================== */

// smartphones (portrait view)
$screen-xs: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;
