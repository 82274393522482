@import '../../assets/scss/partials/vars';

.blockwrap.intro {
    display: block;
    background: $black;
    color: $white;
    text-align: center;
    border-top: solid $white 15px;

    @media(min-width: $screen-md){
        border-top: solid $white 25px;
    }

    .ywca-bg {
        z-index: 1;
        position: relative;  
        height: 60px; 
        width: 150px;
        margin: 0 auto;
        background: url('./i/bg-half-circle.png') top center no-repeat; 

        img.ywca-logo{
            z-index: 2;
            position: absolute;
            max-width: 100px;
            left: 50%;
            top: 8px;
            margin-right: -50%;
            transform: translate(-50%, 0%);
            //max-height: 50px;

            @media(min-width: $screen-md){
                //max-height: 60px;
            }
        }

    }   

    

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 45px;
        padding-bottom: 55px;

        @media(min-width: $screen-md){
            flex-direction: row;
            padding-bottom: 80px;
        }

        img {
            max-height: 210px;

            @media(min-width: $screen-md){
                max-height: 325px;
                margin-right: 50px;
            }
        }

        .copy {
            text-align: center;
            justify-content: center;

            @media(min-width: $screen-md){
                text-align: left;
            }
    
            h1 {
                margin: 25px auto 6px;
                color: $white;
                font-family: "League Gothic", sans-serif;
                font-size: 14vw;
                font-weight: 400;
                text-transform: uppercase;
                

                @media(min-width: $screen-md){
                    font-size: 5rem;
                }
            }
        
            p {
                color: $white;
                font-size: 5vw;
                font-family: $baseFont;
                //letter-spacing: 0.75px;

                @media(min-width: $screen-md){
                    font-size: 1.4rem;
                }
            }
        }

    }

    
}

.blockwrap.video {
    display: block;
    //height: 60vw;
    //background: url('./i/video-bg.png') top center no-repeat, #FFF;
    //background-size: cover;
    background: #262626;

    .video-wrap {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        //max-width: 400px;
        //height: 260px;
        margin: 0 auto;
        padding-top: 56.6%;

        @media(min-width: $screen-md){
            height: 450px;
            max-width: 100%;
        }

        @media(min-width: $screen-lg){
            height: 576px;
            //max-width: 800px;
        }

        div {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }

        // #video {
        //     display: block;
        //     width: 100%;
        //     top: 0px;
        // }
        
        // img {
        //     position: relative;
        //     display:none;
        //     width: 100%;

        //     @media(max-width: 768px){
        //         display: block;
        //     }
        // }
    }

}

.blockwrap.badges {
    display: flex;
    flex-direction: column;
    padding: 20px 0 80px 0;
    //background: $black;
    background: url('./i/bg-stick.png') 0px 220px no-repeat, #000;
    background-size: contain;
    color: $white;

    @media(min-width: $screen-md){
        padding: 20px 0 60px 0;
        background: url('./i/bg-stick-desktop.jpg') top center no-repeat, #000;
        background-size: contain;
    }

    h2 {
        margin: 25px auto 6px;
        color: $white;
        font-family: "League Gothic", sans-serif;
        //font-size: 2.9rem;
        font-size: 14vw;
        font-weight: 400;
        text-transform: uppercase;

        @media(min-width: $screen-md){
            margin: 45px auto 6px;
            font-size: 5rem;
        }
    }

    p {
        color: $white;
        //font-size: 1.2rem;
        font-size: 5vw;
        font-family: $baseFont;

        @media(min-width: $screen-md){
            font-size: 1.4rem;
        }

    }

    .downloads{
        display: flex;
        flex-direction: column;
        width: 100%;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            margin: 40px auto;
            width: 80%;
        }
    }

    a.badge {
        display: block;
        margin: 55px auto 0;
       // max-width: 91px;
        height: 140px;
        cursor: pointer;

        &.mnhl, &.mmls {
            max-width: 120px;
        }

        @media(min-width: $screen-md){
            display: flex;
            height: 140px;
            margin: 0 16px;
        }
        @media(min-width: $screen-lg){
            display: flex;
            height: 170px;
            margin: 0 16px;
        }

        img {
            height: 100%;
        }
    }

    a.arrow-down {
        display: block;
        margin: 30px auto 0;
        height: 35px;
        width: 35px;
        border: solid 3px $white;
        border-radius: 35px;
        background: url('./i/arrow-down.png') center center no-repeat;
        background-size: 15px;
    }

}

.blockwrap.image-gallery {
    background: $black;
    padding: 16px 0;

    .container {
        box-sizing: border-box;
        padding: 0 20px 0 20px;

        @media(min-width: $screen-md){
            
        }
         
        img {
            display: inline-block;
            margin-bottom: 50px;
            width: 100%;
            max-width: 420px;

            @media(min-width: $screen-md){
                width: 48%;
                max-width: 420px;

                &:first-child, &:nth-child(3){
                    margin-right: 50px;
                }
            }

            
        }

    }

    
    
}

.blockwrap.stickers {
    
    padding: 20px 0 0px;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-around;
            padding: 0 20px 0 20px;
        }

        .copy {
            text-align: center;

            @media(min-width: $screen-md){
                margin-top: 60px;
                text-align: left;
            }
        }

        h2 {
            margin: 25px auto 6px;
            color: $black;
            font-family: "League Gothic", sans-serif;
            //font-size: 3rem;
            font-size: 14vw;
            font-weight: 400;
            text-transform: uppercase;

            @media(min-width: $screen-md){
                font-size: 5rem;
            }
        }
    
        p {
            margin: 0 auto;
            max-width: 80%;
            font-family: $baseFont;
            //font-size: 1.2rem;
            font-size: 5vw;
    
            @media(min-width: $screen-md){
                max-width: 100%;
                font-size: 1.4rem;
                
            }
        }
    
        p.tag-us {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px auto;
            font-family: $baseFont;
            //font-size: 1.2rem;
            font-size: 5vw;

            @media(min-width: $screen-md){
                justify-content: left;
                font-size: 1.4rem;

            }
    
            span {
                font-weight: bold;
            }
    
            .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                width: 35px;
                margin: 0 12px 0 0;
                color: $white;
                background: $black;
                border-radius: 30px;
    
                i {
                    font-size: 1.75rem;
                }
            }
        }
    
        img {
            display: block;
            margin-top: 20px;
            width: 100%;
    
            @media(min-width: $screen-md){
                margin-top: 0;
                max-width: 400px;
            }
        }

    }

}

.blockwrap.footer {
    padding: 55px 0 20px;
    background: $black;
    color: $white;

    .container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: left;
        }

    }

    .copy {
        width: 100%;

        @media(min-width: $screen-md){
            width: 60%;
            text-align: left;
            border-right: 1px solid #a3a1a1;
        }

        p {
            max-width: 80%;
            margin: 0 auto 0px;
            color: $white;
            font-family: $baseFont;
            //font-size: 1.2rem;
            font-size: 5vw;

            @media(min-width: $screen-md){
                margin: 50px 0 0 0;
                font-size: 1.4rem;
            }
            
        }
    
        a.learn-more {
            display: block;
            max-width: 150px;
            margin: 36px auto;
            padding: 8px 12px;
            background: $white;
            text-align: center;
            color: $black;
            border-radius: 20px;
            font-family: "League Gothic", sans-serif;
            //font-size: 1.6rem;
            font-size: 4.2vw;
            font-weight: 400;
            text-transform: uppercase;
            text-decoration: none;
            
    
            &:hover {
                color: $white;
                background: $black;
                transition: all 0.35s linear;
            }

            @media(min-width: $screen-md){
                margin: 36px 0 0 0;
                font-size: 1.6rem;
            }

        }

        .keyline {
            display: block;
            margin: 65px auto 50px;
            width: 80%;
            height: 2px;
            background: #f1f1f1;

            @media(min-width: $screen-md){
                display: none;
            }
        }
    }

    .social-wrap{

        @media(min-width: $screen-md){
            width: 40%;
            padding: 50px 0 50px 30px;
        }

        img {
            max-width: 150px;
            margin: 0px auto 20px;

            @media(min-width: $screen-md){
                max-width: 220px;
            }
        }

        p.social {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 80%;
            margin: 16px auto;

            @media(min-width: $screen-md){
                max-width: unset;
            }
            
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                width: 35px;
                margin: 0 8px;
                border-radius: 25px;
                color: $black;
                background: $white;
                outline: none;
                font-size: 1.65rem;
                text-decoration: none;
    
                &:hover {
                    color: $white;
                    background: $black;
                    transition: all 0.35s linear;
                }
            }
    
        }
    }
    
}

.blockwrap.footer-logos{
    display: block;
    background: $black;
    padding-bottom: 50px;
     

        .more-ywca {

            @media(min-width: $screen-md){
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            a {
                display: inline-block;
                width: 30%;
                margin: 16px;
                cursor: pointer;
    
                @media(min-width: $screen-md){
                    width: 125px;
                }
    
                img {
                    display: block;
                    width: 100%
                }
            }
        }

    

}